<template>
  <div></div>
</template>


------------------ script ------------------
<script>
import {redirectV2Api} from "../api/v2/redirectV2Api";


export default {
  async created() {
    const query = this.$route.query
    const url = await redirectV2Api.findUrlById(query.id);
    window.location.href = url
  }
}
</script>


------------------ styles ------------------
<style lang="scss" scoped></style>
